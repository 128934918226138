import React from 'react';
import styled from '@emotion/styled';

import CatalogueCategory from '@/containers/Catalogue/CatalogueCategory';
import {  Content, ContactBox } from '@/components/molecules';
import { PrismicCataloguePage } from '@/domain/PrismicCataloguePage';
import { PrismicCategory } from '@/domain/PrismicCategory';

interface CatalogueContainerProps {
    data: {
        prismicCataloguePage: PrismicCataloguePage;
        allPrismicCa: {
            edges: {
                node: PrismicCategory;
            }[];
        }
    };
    lang: string;
}

const CatalogueContainerRoot = styled.section``;

const CatalogueContainer = ({ data, lang }: CatalogueContainerProps): JSX.Element => {
    const { catalogue_content } = data?.prismicCataloguePage?.data;
    
    const ContentData = {
        content: catalogue_content?.raw
    }

    const Categories = data?.allPrismicCa?.edges?.map(node => ({
        uid: node?.node?.uid,
        title: node?.node?.data?.category_title?.text,
        image: node?.node?.data?.category_image
    }))
    return (
        <CatalogueContainerRoot>
            <Content {...ContentData} />
            <CatalogueCategory categories={Categories} />
            <ContactBox lang={lang} />
        </CatalogueContainerRoot>
    );
};

export default CatalogueContainer;