import React from 'react';
import { graphql } from 'gatsby';
import mq from '@/styles/mq';
import { SEO } from '@/components/atoms';
import { Layout } from '@/components/organisms';

import CatalogueContainer from '@/containers/Catalogue/CatalogueContainer';
import { PrismicCataloguePage } from '@/domain/PrismicCataloguePage';
import { PrismicCategory } from '@/domain/PrismicCategory';
import { PageContext } from '@/domain/PageContext';
interface CatalogueTemplateProps {
  data: {
    prismicCataloguePage: PrismicCataloguePage;
    allPrismicCa: {
        edges: {
            node: PrismicCategory;
        }[];
    }
  };
  pageContext: PageContext;
}

export const query = graphql`
  query ($id: String!, $lang: String!) {
    prismicCataloguePage(id: { eq: $id }) {
      id
      lang
      alternate_languages {
        lang
        uid
      }
      data {
        meta_title
        meta_description
        catalogue_content {
          raw
        }
      }
    }
    allPrismicCa(filter: {lang: {eq: $lang}}) {
      edges {
        node {
          uid
          lang
          data {
            category_title {
              text
            }
            category_image {
              alt
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

const CatalogueTemplate = ({
  data,
  pageContext,
}: CatalogueTemplateProps): JSX.Element => {
  const { alternate } = pageContext;
  const { lang, uid, alternate_languages } = data?.prismicCataloguePage;
  const { meta_title, meta_description } = data?.prismicCataloguePage?.data;

  const selectlang = {
    lang,
    uid: uid,
    alternate_languages,
  };
  const seo = {
    title: meta_title,
    description: meta_description,
    lang: lang,
    url: `${lang}`,
  };
  const linkAlternate = alternate?.map((data) => ({
    lang: data?.lang,
    url: `${data?.lang}/${data?.uid}`,
  }));
  return (
    <Layout selectLang={selectlang}>
      <SEO
        lang={seo.lang}
        title={`${seo.title}`}
        url={seo.url}
        description={seo.description}
        alternate={linkAlternate}
      />
      <CatalogueContainer data={data} lang={lang} />
    </Layout>
  );
};

export default CatalogueTemplate;
