import React from 'react';
import styled from '@emotion/styled';

import { CategoryThumbnail } from '@/components/molecules';
import ImageDataType from '@/types/data/image';
import mq from '@/styles/mq';


interface CatalogueCategoryProps {
   categories: {
        uid: string;
        title: string;
        image: ImageDataType;
   }[];
}

const CatalogueCategoryRoot = styled.section`
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    ${mq('xs')} {
        padding-top: ${({ theme }) => theme.spacing(3)};
        padding-left: ${({ theme }) => theme.spacing(3)};
        padding-right: ${({ theme }) => theme.spacing(3)};
        padding-bottom: ${({ theme }) => theme.spacing(3)};
    }
    ${mq('md')} {
        padding-top: ${({ theme }) => theme.spacing(5)};
        padding-left: ${({ theme }) => theme.spacing(5)};
        padding-right: ${({ theme }) => theme.spacing(5)};
        padding-bottom: ${({ theme }) => theme.spacing(5)};
    }
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const CatalogueCategory = ({ categories }: CatalogueCategoryProps): JSX.Element => {

    return (
        <CatalogueCategoryRoot>
            {categories?.map((category, i) => (
                <CategoryThumbnail key={i} {...category} />
            ))}
        </CatalogueCategoryRoot>
    );
};

export default CatalogueCategory;